












































import { defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { APPLICATION_VERSION } from "@/lib/constants/env";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queryOptions_gyms } from "@/lib/query/functions/gyms";

export default defineComponent({
	setup() {
		const { id: gymId } = useCurrentGym();
		const { data: gyms } = useQuery(queryOptions_gyms());

		return { APPLICATION_VERSION, gymId, gyms };
	},
});
